/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, Box, Heading, jsx, Text } from 'theme-ui'
import ProductList from '~/components/Product/List'
import ContentCallToAction from '~/components/Content/Section/CallToAction'

const ProductSection = ({ title, description, products, cta }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        marginTop: '36px',
        marginBottom: '36px',
        textAlign: 'center'
      }}
    >
      <Heading
        as="h1"
        variant="h1"
        sx={{
          textAlign: 'center',
          color: 'primary',
          variant: ['text.h2', 'text.h2', 'text.h1'],
          marginTop: ['16px', null, '36px'],
          marginBottom: ['8px', null, '32px']
        }}
      >
        {title}
      </Heading>
      {description && (
        <Text
          sx={{
            variant: 'text.body',
            color: 'primary',
            textAlign: 'center'
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html
            }}
          />
        </Text>
      )}
      <ProductList products={products} />
      {cta && (
        <Box
          sx={{
            width: ['100%', '100%', '80%'],
            span: {
              variant: 'text.link'
            },
            margin: 'auto',
            marginBottom: ['8px', '8px', '48px']
          }}
        >
          <ContentCallToAction
            sx={{
              display: 'inline-block',
              marginBottom: ['32px', '32px', '0']
            }}
            content={cta}
          />
        </Box>
      )}
    </Flex>
  )
}

ProductSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    description: PropTypes.string
  }),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  cta: PropTypes.shape({})
}

export default ProductSection
