/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { useAnalytics } from '@chordcommerce/gatsby-theme-performance'

const CollectionLink = ({
  id,
  title,
  slug,
  description,
  imageUrl,
  children,
  sx,
  ...props
}) => {
  const { trackCollectionClicked } = useAnalytics()

  const url = `/shop/?collection=${slug}`

  return (
    <Link
      to={url}
      onClick={() =>
        trackCollectionClicked({
          id,
          title,
          slug,
          imageUrl,
          description: description.description
        })
      }
      sx={{
        ...sx
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

CollectionLink.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  slug: PropTypes.string,
  imageUrl: PropTypes.string,
  description: PropTypes.shape({
    description: PropTypes.string
  }),
  children: PropTypes.node.isRequired
}

export default CollectionLink
